import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import Layout from '../components/Layout';
import Band from '../components/Band/Band';
import { Container, ContainerWide } from '../components/Grid/Grid';
import SEO from '../components/SEO';
import GridCards, { Card, CardOverlayTitle } from '../components/GridCards/GridCards';
import DiscoverMore from '../components/DiscoverMore/DiscoverMore';
import Cover from '../components/Cover/Cover';
import Button from '../components/Button/Button';
import BR from '../components/BR';

import { grid } from '../constants/grid';

import cardImageLogo from '../images/card-logo-light.svg';

const UppercaseText = styled.p`
    text-transform: uppercase;
    letter-spacing: 1;
    font-size: 12px;

    @media screen and (min-width: ${grid.breakpoints.xs}px) {
        font-size: 14px;
    }
`

const BrandsPage = ({ data }) => (
    <Layout>

        <SEO title="Brands" description="Introducing your brand to planet friendly buyers, creating new opportunities for you to grow." />

        <Band size="xs" pb={0}>
            <ContainerWide>
                <GridCards>
                    <Card image={cardImageLogo} theme="gray">
                        <CardOverlayTitle>Brands</CardOverlayTitle>
                    </Card>
                    <Card image={data.cardImage1} />
                    <Card image={data.cardImage2} />
                </GridCards>
            </ContainerWide>
        </Band>

        <Band size="sm">
            <Container>
                <DiscoverMore>
                    <p>Introducing your brand to planet friendly buyers, creating new opportunities for you to grow.</p>
                    <Button href="#more">Discover More</Button>
                </DiscoverMore>
            </Container>
        </Band>

        <Band theme="gray" size="md" id="more">
            <Container>
                <h2 style={{ marginBottom: 15 }}>How we can help</h2>
                <p style={{ marginBottom: 0, lineHeight: 1.65 }}>We feel strongly about the importance of ethical brands.</p>
                <p style={{ marginBottom: 0, lineHeight: 1.65 }}>We believe consumers have a right to make informed purchasing decisions.</p>
                <p style={{ marginBottom: 0, lineHeight: 1.65 }}>We work tirelessly to put your brand in front of them via our developing network of planet friendly buyers.</p>
                <p style={{ marginBottom: 0, lineHeight: 1.65 }}>We would be delighted to hear from you and very proud to work with you.</p>
            </Container>
        </Band>

        <Cover image={data.coverImage1} />

        <Band size="lg">
            <Container>
                <h2>Promoting great business. <BR />
                    Working with the planet not against it.</h2>
                <UppercaseText>Changing mindsets, supporting policies</UppercaseText>
            </Container>
        </Band>

    </Layout>
);

export const pageQuery = graphql`
    query {
        cardImage1: file(relativePath: { eq: "card-9.jpg" }) {
            ...cardImageFragment
        }
        cardImage2: file(relativePath: { eq: "card-8.jpg" }) {
            ...cardImageFragment
        }
        coverImage1: file(relativePath: { eq: "cover-2.jpg" }) {
            ...coverImageFragment
        }
    }
`

export default BrandsPage;
